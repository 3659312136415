<template>
  <div class="container h-p100">
    <div class="row align-items-center justify-content-md-center h-p100">

      <div class="col-12">
        <div class="row justify-content-center g-0">
          <div class="col-lg-5 col-md-5 col-12">
            <div class="bg-white rounded10 shadow-lg">
              <div class="content-top-agile p-20 pb-0">
                <h2 class="text-primary">
                  <img src="/images/logo.png" style="filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);height: 44px;">
                </h2>
                <p class="mb-0">เข้าสู่ระบบ</p>
              </div>
              <div class="p-40">
                <form>
                  <div :class="['form-group', {'error': errorFor('email')}]">
                    <div class="input-group mb-3">
                      <span class="input-group-text bg-transparent"><i class="ti-user"></i></span>
                      <input type="text"
                             v-model="form.email"
                             class="form-control ps-15 bg-transparent"
                             placeholder="บัญชีผู้ใช้งาน">
                    </div>

                    <v-errors :errors="errorFor('email')"></v-errors>
                  </div>
                  <div :class="['form-group', {'error': errorFor('password')}]">
                    <div class="input-group mb-3">
                      <span class="input-group-text  bg-transparent"><i class="ti-lock"></i></span>
                      <input type="password"
                             v-model="form.password"
                             class="form-control ps-15 bg-transparent"
                             placeholder="รหัสผ่าน">
                    </div>

                    <v-errors :errors="errorFor('password')"></v-errors>
                  </div>
                  <div class="row">
                    <div class="col-6"></div>
                    <!-- /.col -->
                    <div class="col-6">
                      <div class="fog-pwd text-end">
                        <a href="javascript:void(0)" class="hover-warning"><i class="ion ion-locked"></i> ลืมรหัสผ่านเข้าสู่ระบบ</a><br>
                      </div>
                    </div>
                    <!-- /.col -->
                    <div class="col-12 text-center">
                      <button type="submit"
                              @click.prevent="login"
                              :disabled="loading"
                              class="btn btn-danger mt-10 me-2">เข้าสู่ระบบ</button>

                      <button class="btn btn-info mt-10"
                              @click.prevent="googleSignIn"
                              :disabled="loading"
                              type="button"><i class="fab fa-google"></i> Sign In With Google</button>
                    </div>
                    <!-- /.col -->
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
        device: 'web'
      }
    }
  },
  created() {
    if ("code" in this.$route.query) {
      this.loading = true;

      this.$store.dispatch('auth/login', {
          code: this.$route.query.code,
          device: 'web'
        })
        .then(() => window.location.href = process.env.VUE_APP_BASE)
        .catch((err) => this.allErrors = err.errors)
        .finally(() => this.loading = false);
    }
  },
  methods: {
    login() {
      this.allErrors = null;
      this.loading = true;

      this.$store.dispatch('auth/login', this.form)
        .then(() => window.location.href = process.env.VUE_APP_BASE)
        .catch((err) => this.allErrors = err.errors)
        .finally(() => this.loading = false);
    },
    googleSignIn() {
      // Google's OAuth 2.0 endpoint for requesting an access token
      let oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      let form = document.createElement('form');
      form.setAttribute('method', 'GET'); // Send as a GET request.
      form.setAttribute('action', oauth2Endpoint);

      // Parameters to pass to OAuth 2.0 endpoint.
      let params = {
        'client_id': process.env.VUE_APP_GOOGLE_CLIENT_ID,
        'redirect_uri': process.env.VUE_APP_GOOGLE_REDIRECT_URI,
        'scope': 'https://www.googleapis.com/auth/userinfo.profile email',
        'access_type': 'offline',
        'include_granted_scopes': 'true',
        'response_type': 'code',
        'state': 'google'
      };

      // Add form parameters as hidden input values.
      for (let p in params) {
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
    },
  }
}
</script>